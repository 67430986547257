import React, {useRef } from "react";
import { Link } from "react-router-dom";
import CryptoJs from "crypto-js";

const IsAdmin = () => {
  const appCipher = localStorage.getItem("app");
  if (appCipher !== null && appCipher !== undefined) {
    try {
      let appBytes = CryptoJs.AES.decrypt(
        appCipher,
        process.env.REACT_APP_KEY_SECRET
      );
      const appObject = JSON.parse(appBytes.toString(CryptoJs.enc.Utf8));
      if (appObject.category === "Admin"){
        return true;
      }else{
        return false;
      }
    } catch (error) {
      return false;
    }
  } else return false;
};

const Header = () => {
    const navbarRef = useRef(null);

    const dropdownRef = useRef(null);

    function toggleNavbarMobile() {
        navbarRef.current.classList.toggle('navbar-mobile');
        navbarRef.current.classList.toggle('bi-list');
        navbarRef.current.classList.toggle('bi-x');
    }

    function navLinkMobileClicked(){
      navbarRef.current.classList.remove('navbar-mobile');
      navbarRef.current.classList.remove('bi-list');
      navbarRef.current.classList.remove('bi-x');
      dropdownRef.current.classList.remove('dropdown-active');
    }

    function droudownToggle(){
      dropdownRef.current.classList.toggle('dropdown-active');
    }

  return (
    <header id="header" className="header fixed-top">
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <Link to="/" className="logo d-flex align-items-center">
          <img src="assets/img/night-teer.png" alt="" />
        </Link>

        <nav id="navbar" className="navbar" ref={navbarRef}>
          <ul>
            <li>
              <Link className="nav-link " to="results" onClick={navLinkMobileClicked}>
                Result
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="common-numbers" onClick={navLinkMobileClicked}>
                Common Number
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="previous-results" onClick={navLinkMobileClicked}>
                Previous Results
              </Link>
            </li>
            <li>
              <Link className="nav-link" to="dream-numbers" onClick={navLinkMobileClicked}>
                Dream Numbers
              </Link>
            </li>            

            {IsAdmin() && (
              <>
              <li className="dropdown"><button className="nav-button" type="button" onClick={droudownToggle}><span>Admin</span> <i className="bi bi-chevron-down"></i></button>
              <ul ref={dropdownRef}>
                <li>
                  <Link className="nav-link" to="xc5af" onClick={navLinkMobileClicked}>
                    Update Result
                  </Link>  
                </li> 
                <li>
                  <Link className="nav-link" to="cno4d" onClick={navLinkMobileClicked}>
                    Update Common Numbers
                  </Link>  
                </li> 
                <li>
                  <Link className="nav-link" to="lco0w" onClick={navLinkMobileClicked}>
                    Change Password
                  </Link>  
                </li> 
              </ul>
            </li>

              <li>
                <Link className="nav-link" to="/admin" onClick={navLinkMobileClicked}>
                  Logout
                </Link>
              </li>
              </>
            )}
          </ul>
          <i className="bi bi-list mobile-nav-toggle" onClick={toggleNavbarMobile}></i>
        </nav>
      </div>
    </header>
  );
};

export default Header;
